import React from 'react';

function Home() {
  return (
    <div> 
    
        <h2 className="text-2xl font-semibold mb-5">Page Title</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus eget justo et iaculis. Quisque vitae nulla malesuada, auctor arcu vitae, luctus nunc. Morbi ac venenatis enim, at molestie lacus. Nunc convallis id nisi sed blandit. Aliquam erat volutpat. Sed ut dui ac nisi vulputate fringilla. Mauris efficitur at massa non blandit.
        </p>
    </div>
  );
}

export default Home;