import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Databases } from 'appwrite';
import axios from 'axios';
import { client } from './appwriteConfig'; 

function ViewDocument() {
    const { id } = useParams();
    const [docData, setDocData] = useState({}); 
    const [data, setData] = useState({});
    const [nestedEntries, setNestedEntries] = useState({});
    const [loading, setLoading] = useState(false); 
    useEffect(() => {
        const db = new Databases(client);
        async function fetchData() {
            try {
                const result = await db.getDocument('6606ae48b65524813d7d', '663b6cd0001c437f8c19', id);
                const fetchedData = JSON.parse(result.Json);
                setDocData(fetchedData);
                initializeForm(fetchedData.data);
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        }
        fetchData();
    }, [id]);

    const initializeForm = (initialData) => {
        setData(initialData);
        Object.keys(initialData).forEach(key => {
            if (Array.isArray(initialData[key])) {
                setNestedEntries(prev => ({ ...prev, [key]: initialData[key] }));
            }
        });
    };

    const renderField = (key, value) => {
        if (typeof value === 'boolean') {
            return (
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    {formatLabel(key)}
                    <input 
                        type="checkbox"
                        checked={value}
                        onChange={(e) => handleInputChangeBool(e, key)}
                        className="form-checkbox h-6 w-6 text-green-500 focus:outline-none focus:shadow-outline"
                    />
                </label>
            );
        } else {
            return (
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    {formatLabel(key)}
                    <input 
                        type="text"
                        value={value}
                        onChange={(e) => handleInputChange(e, key)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder={`Enter ${formatLabel(key)}`}
                    />
                </label>
            );
        }
    };

    const handleInputChangeBool = (e, key) => {
        setData(prev => ({ ...prev, [key]: e.target.checked }));
    };

    
    const formatLabel = (label) => {
        return label.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    };
    const handleInputChange = (e, key) => {
        setData(prev => ({ ...prev, [key]: e.target.value }));
    };

    const handleSubmit = async () => {
        setLoading(true); 
        const fullPayload = {
            ...docData,
            data: {
                ...data,
                ...Object.fromEntries(Object.keys(nestedEntries).map(key => [key, nestedEntries[key]]))
            }
        };

        try {
            const response = await axios.post('https://app.documentero.com/api', fullPayload);
            setLoading(false); 
            if (response.status === 200 && response.data.status === 200) {
                window.location.href = response.data.data;
            } else {
                alert('Failed to generate document. ' + response.data.message);
            }
        } catch (error) {
            setLoading(false); 
            console.error('Submission failed:', error);
            alert('Submission failed. Check console for more details.');
        }
    };

    const handleNestedChange = (e, arrayKey, index, fieldKey) => {
        const updatedArr = nestedEntries[arrayKey].map((item, idx) => {
            if (idx === index) {
                return { ...item, [fieldKey]: e.target.value };
            }
            return item;
        });
        setNestedEntries(prev => ({ ...prev, [arrayKey]: updatedArr }));
    };

    const handleAddItem = (arrayKey) => {
        const newItem = {};
        Object.keys(data[arrayKey][0]).forEach(key => {
            newItem[key] = '';
        });
        setNestedEntries(prev => ({
            ...prev,
            [arrayKey]: [...prev[arrayKey], newItem]
        }));
    };

    const constructPayload = () => {
        const fullPayload = {
            ...docData,  
            data: {
                ...data,
                ...Object.fromEntries(Object.keys(nestedEntries).map(key => [key, nestedEntries[key]]))
            }
        };
        return JSON.stringify(fullPayload, null, 2);
    };

    return (
        <div className="container mx-auto p-4">
        <h1 className="text-xl font-bold mb-4">Generate Document</h1>
        <div className="flex flex-wrap">
            {Object.keys(data).map(key => !Array.isArray(data[key]) && (
                <div key={key} className="w-full md:w-1/3 p-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {formatLabel(key)}
                    </label>
                    <input 
                        type="text"
                        onChange={(e) => handleInputChange(e, key)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder={`Enter ${formatLabel(key)}`}
                    />
                </div>
                ))}
            </div>
            {Object.keys(nestedEntries).map(arrayKey => (
                <div key={arrayKey} className="mb-6">
                    <h2 className="text-lg font-semibold">{arrayKey}</h2>
                    <button onClick={() => handleAddItem(arrayKey)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Add New {arrayKey}
                    </button>
                    <div className="mt-4">
                        {nestedEntries[arrayKey].map((item, index) => (
                            <div key={index} className="flex mb-2">
                                {Object.keys(item).map(fieldKey => (
                                    <input 
                                        key={fieldKey}
                                        type="text"
                                        onChange={(e) => handleNestedChange(e, arrayKey, index, fieldKey)}
                                        className="flex-1 m-1 shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        placeholder={`Enter ${fieldKey}`}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>

                    
                </div>
                
            ))}
            <pre className="bg-gray-100 border rounded p-3 mt-4">
                {constructPayload()}
            </pre>
            <button onClick={handleSubmit} 
                    disabled={loading} 
                    className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                {loading ? 'Generating Document...' : 'Submit'}
            </button>
            {loading && <p className="text-gray-500 mt-2">Please wait, your document is being generated.</p>}
        </div>
    );
}

export default ViewDocument;