import React, { useEffect, useState } from 'react';
import { Databases } from 'appwrite';
import { client } from './appwriteConfig';
import { useNavigate } from 'react-router-dom';

function Products() {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newProductName, setNewProductName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const db = new Databases(client);
    try {
      const result = await db.listDocuments('6606ae48b65524813d7d', '660abd18002b4bab15c6');
      setProducts(result.documents);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    const db = new Databases(client);
    try {
      await db.createDocument('6606ae48b65524813d7d', '660abd18002b4bab15c6', 'unique()', { Name: newProductName });
      setNewProductName('');
      setShowModal(false); 
      fetchProducts(); 
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Products</h2>
        {/* Add Product Button */}
        <button 
          onClick={() => setShowModal(true)}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Product
        </button>
      </div>

      {/* Product List Table */}
      <table className="table-auto w-full">
        <thead className="bg-slate-300">
          <tr>
            <th className="px-4 py-2 text-left">Product Name</th>
            <th className="px-4 py-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <tr key={product.$id}>
              <td className="px-4 py-2">{product.Name}</td>
              <td className="px-4 py-2">
                <button 
                  onClick={() => navigate(`/view-product/${product.$id}`)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Adding Product */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Add New Product</h3>
              <form onSubmit={handleAddProduct} className="mt-4">
                <input
                  type="text"
                  placeholder="Product Name"
                  value={newProductName}
                  onChange={(e) => setNewProductName(e.target.value)}
                  className="mt-2 px-4 py-2 w-full border rounded-md"
                />
                <div className="items-center px-4 py-3">
                  <button 
                    type="submit"
                    className="px-4 py-2 bg-blue-500 p-3 rounded-lg text-white hover:bg-blue-400"
                  >
                    Add
                  </button>
                  <button 
                    onClick={() => setShowModal(false)}
                    className="ml-4 px-4 py-2 bg-gray-500 p-3 rounded-lg text-white hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Products;