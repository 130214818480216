import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import MyLeads from './MyLeads';
import ViewLead from './ViewLead';
import ViewCompany from './ViewCompany';
import Login from './Login';
import Nav from './Nav';
import TopBar from './TopBar';
import Settings from './Settings';
import Products from './Products';
import ViewProduct from './ViewProduct';
import ViewDocument from './ViewDocument';
import Documents from './Documents';
import { LayoutProvider } from './LayoutContext';
import { account } from './appwriteConfig';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const userDetails = await account.get();
        setUserEmail(userDetails.email); 
        setIsAuthenticated(true);
        const session = await account.getSession('current');
      } catch (error) {
        console.error('Failed to retrieve session or user details:', error);
        setIsAuthenticated(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsNavExpanded(true);
      } else {
        setIsNavExpanded(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    checkSession();

    return () => window.removeEventListener('resize', handleResize); // Clean up
  }, []);


  return (
    <Router>
      <LayoutProvider value={{ isNavExpanded, setIsNavExpanded }}>
        <div className="font-sans min-h-screen flex flex-col">
          {isAuthenticated && <TopBar onLogout={() => setIsAuthenticated(false)} />}
          <div className={`flex flex-1 ${isAuthenticated ? '' : 'justify-center'}`}>
            {isAuthenticated && <Nav />}
            <main className={`flex-1 transition-all duration-300 ease-linear ${isNavExpanded ? 'ml-64' : 'ml-16'} bg-gray-100`}>
              <div className="shadow-lg rounded-lg overflow-hidden p-5 m-4 bg-white">
                {isAuthenticated ? (
                  <Routes>
                    <Route path="/myleads" element={<MyLeads />} />
                    <Route path="/view-company/:id" element={<ViewCompany />} />
                    <Route path="/view-lead/:id" element={<ViewLead />} />
                    <Route path="/view-product/:id" element={<ViewProduct />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/documents" element={<Documents />} />
                    <Route path="/view-document/:id" element={<ViewDocument />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                ) : (
                  <Login onLogin={(loggedIn) => setIsAuthenticated(loggedIn)} />
                )}
              </div>
            </main>
          </div>
        </div>
      </LayoutProvider>
    </Router>
  );
}

export default App;