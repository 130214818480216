import React, { useState } from 'react';
import { Databases, Query } from 'appwrite';
import { client } from './appwriteConfig';

function Settings() {
  const [currentProcess, setCurrentProcess] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");

  const triggerWebhooksForAllCompanies = async () => {
    setIsUpdating(true);
    setUpdateStatus("Starting leads update...");

    const db = new Databases(client);
    const leadCollectionId = '6606f60c0019b772d53f';

    try {
      const result = await db.listDocuments('6606ae48b65524813d7d', leadCollectionId, [
      ]);

      const leads = result.documents;

      for (let i = 0; i < leads.length; i++) {

        const leadPayload = {
          leadId: leads[i].$id,
          ownerEmail: leads[i].Owner_email,
        };

        // await fetch('https://am01.coffeecup.solutions/webhook/LeadUpdate', {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify(leadPayload),
        // });
        console.log(leads[i]);


        setUpdateStatus(`Processing lead ${i + 1} of ${leads.length}`);
        return;


      }

      setUpdateStatus("Leads update complete.");
    } catch (error) {
      console.error("Error updating leads:", error);
      setUpdateStatus("Error occurred during leads update.");
    }

    setIsUpdating(false);
  };

  const triggerGetCallsAndUpdate = async () => {
    setIsUpdating(true);
    setUpdateStatus("Fetching calls...");

    const db = new Databases(client);
    const collectionId = '66099411002504d32bb2';

    try {
      const getCallsResponse = await fetch('https://am01.coffeecup.solutions/webhook/GetCalls');
      const callsData = await getCallsResponse.json();

      let processedCallsCount = 0;

      for (let callItem of callsData) {
        console.log(callItem);

        for (let data of callItem.data) {
          console.log(data.id);
          const result = await db.listDocuments('6606ae48b65524813d7d', collectionId, [
            Query.equal('id', parseInt(data.id))
          ]);

          if (result.total === 0) {
            await fetch('https://am01.coffeecup.solutions/webhook/CallCreate', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data),
            });
            processedCallsCount++;
            setCurrentProcess(`Processing call data ${processedCallsCount} of total entries.`);
          } else {
            console.log(`Call with ID ${data.id} already exists. Skipping.`);
          }
        }
      }

      setUpdateStatus(`All ${processedCallsCount} call data entries processed!`);
    } catch (error) {
      console.error("Error in fetching or updating calls:", error);
      setUpdateStatus(`Error: ${error.message}`);
    }
    setIsUpdating(false);
  };
  const checkCompanies = async () => {
    setIsUpdating(true);
    setCurrentProcess("Checking all companies...");

    const db = new Databases(client);
    const collectionId = '6606f60c0019b772d53f'; // ZohoLeads collection ID
    const limit = 100;
    let currentPage = 1;

    const companyOwners = {}; // Track company leads' owners

    try {
      let total = 0;

      do {
        const queries = [Query.limit(limit), Query.offset((currentPage - 1) * limit)];
        const result = await db.listDocuments('6606ae48b65524813d7d', collectionId, queries);
        if (!total) {
          total = result.total;
        }

        // Populate companyOwners with document data
        result.documents.forEach(doc => {
          const { Company, Owner_name } = doc;
          if (!companyOwners[Company]) {
            companyOwners[Company] = new Set(); // Use a Set to handle unique Owner_Names
          }
          companyOwners[Company].add(Owner_name);
        });

        currentPage++;
      } while ((currentPage - 1) * limit < total);

      // Now filter companies with more than one distinct owner
      const companiesWithMultipleOwners = Object.entries(companyOwners).filter(([_, owners]) => owners.size > 1);

      // Just for logging purposes
      console.log("Companies with varying owners:", companiesWithMultipleOwners);
      companiesWithMultipleOwners.forEach(([company, owners]) => {
        console.log(`Company: ${company}, Owners: ${Array.from(owners).join(', ')}`);
      });

      setCurrentProcess(`Checked all companies. Found ${companiesWithMultipleOwners.length} companies with varying owners. (Check the console for the list)`);
    } catch (error) {
      console.error("Error checking companies:", error);
      setCurrentProcess("Error occurred while checking companies.");
    }

    setIsUpdating(false);
  };

  const SyncLeads = async () => {
    setIsUpdating(true);
    setCurrentProcess("Syncing leads from zoho...");
    setUpdateStatus("Grabbing leads...");

    const updateNotes = async (leadId, awLeadId) => {
      try {
        await fetch(`https://am01.coffeecup.solutions/webhook/UpdateLeadNotes?id=${leadId}&awLeadId=${awLeadId}`);
      } catch (error) {
        console.error("Error triggering webhook:", error);
      }
    };

    const db = new Databases(client);
    const leadsCollectionId = '6606f60c0019b772d53f';
    const companiesCollectionId = '66095f75001c8c22ca8f';
    const webhookUrl = 'https://am01.coffeecup.solutions/webhook/GetAllLeads';

    try {
      const response = await fetch(webhookUrl);
      const leads = await response.json();
      // const index = leads.findIndex(lead => lead.$id === '6613a1632cf9419b66ee');

      // if (index !== -1) {
      //     leads = leads.slice(index + 1);
      //     console.log('Found Lead')

      // }
      console.log(leads)
      for (const lead of leads) {
        try {
          const companyName = lead.Company;
          let companyDocumentId = null;
          let companyQueryResult = await db.listDocuments('6606ae48b65524813d7d', companiesCollectionId, [
            Query.equal('CompanyName', companyName)
          ]);

          if (companyQueryResult.documents.length > 0) {
            console.log("Company Found");
            companyDocumentId = companyQueryResult.documents[0].$id;

            // Update company with new owner details if it already exists
            const updateCompanyData = {
              Owner_Id: lead.Owner?.id ?? "",
              Owner_Email: lead.Owner?.email ?? "",
              Owner_Name: lead.Owner?.name ?? "",
            };
            console.log("Updating company with", updateCompanyData);

            await db.updateDocument('6606ae48b65524813d7d', companiesCollectionId, companyDocumentId, updateCompanyData);

          } else {
            // Create new Company document
            const companyData = {
              CompanyName: companyName,
              Owner_Id: lead.Owner?.id ?? "",
              Owner_Email: lead.Owner?.email ?? "",
              Owner_Name: lead.Owner?.name ?? "",
            };

            const newCompanyDoc = await db.createDocument('6606ae48b65524813d7d', companiesCollectionId, 'unique()', companyData);
            companyDocumentId = newCompanyDoc.$id;

          }

          // Prepare document data mapping with your database schema for lead
          const documentData = {
            Owner_name: lead.Owner?.name ?? "",
            Owner_id: lead.Owner?.id ?? "",
            Owner_email: lead.Owner?.email ?? "",
            GCLID: lead.GCLID ?? "",
            field_states: lead.$field_states ?? "",
            MeetingDateStageWasChanged: lead.MeetingDateStageWasChanged ?? "",
            id: lead.id ?? "",
            Ops_Review: lead.Ops_Review ?? false,
            Created_Time: lead.Created_Time ? new Date(lead.Created_Time).toISOString() : "",
            Seat_count_estimate: lead.Seat_count_estimate ?? null,
            Outsourced_IT: lead.Outsourced_IT ?? false,
            No_of_Employees: lead.No_of_Employees ?? null,
            Converted__s: lead.$converted ?? false,
            Created_By_name: lead.Created_By?.name ?? "",
            Created_By_id: lead.Created_By?.id ?? "",
            Created_By_email: lead.Created_By?.email ?? "",
            Annual_Revenue: lead.Annual_Revenue ?? "",
            Description: lead.Description?.substr(0, 255) ?? "",
            Website: lead.Website ?? "",
            Internal_Company_Department: lead.Internal_Company_Department ?? "",
            Full_Name: lead.Full_Name ?? "",
            Lead_Status: lead.Lead_Status ?? "",
            Record_Image: lead.Record_Image ?? "",
            Outsourced_Renewal: lead.Outsourced_Renewal ?? "",
            Lead_Conversion_Time: lead.Conversion_Exported_On ? new Date(lead.Conversion_Exported_On).toISOString() : "", // Adjust if necessary
            Lead_Type: lead.Lead_Type ?? "",
            Email_Opt_Out: lead.Email_Opt_Out ?? false,
            Designation: lead.Designation ?? "",
            Keyword: lead.Keyword ?? "",
            Campaign_test_0: lead.Campaign_test?.[0] ?? "",
            Mobile: lead.Mobile ?? "",
            Record_Status__s: lead.$state ?? "",
            Layout_name: lead.Layout?.name ?? "",
            Layout_id: lead.Layout?.id ?? "",
            Locked__s: lead.Locked__s ?? false,
            Lead_Source: lead.Lead_Source ?? "",
            Campaign_SMB: lead.Campaign_SMB ?? "",
            Company: lead.Company ?? "",
            Company_turnover_estimate: lead.Company_turnover_estimate ?? null,
            currency_symbol: lead.$currency_symbol ?? "",
            Visitor_Score: lead.Visitor_Score ?? "",
            Last_Activity_Time: lead.Last_Activity_Time ? new Date(lead.Last_Activity_Time).toISOString() : "",
            Industry: lead.Industry ?? "",
            Lead_Stage: lead.Lead_Stage ?? "",
            City: lead.City ?? "",
            State: lead.State ?? "",
            Secondary_Email: lead.Secondary_Email ?? "",
            CampaignType_Master_0: lead.CampaignType_Master?.[0] ?? "",
            First_Name: lead.First_Name ?? "",
            Modified_By_name: lead.Modified_By?.name ?? "",
            Modified_By_id: lead.Modified_By?.id ?? "",
            Modified_By_email: lead.Modified_By?.email ?? "",
            Phone: lead.Phone ?? "",
            Modified_Time: lead.Modified_Time ? new Date(lead.Modified_Time).toISOString() : "",
            Last_Name: lead.Last_Name ?? "",
            Stage_Date: lead.Stage_Date ? new Date(lead.Stage_Date).toISOString() : "",
            Referrer: lead.Referrer ?? "",
            approval_state: lead.$approval_state ?? "",
            Email: lead.Email ?? "",
            zohoLeadCompanies: companyDocumentId
          };

          console.log(lead);
          // Checking for lead existence by ID
          const existingLeadResult = await db.listDocuments('6606ae48b65524813d7d', leadsCollectionId, [
            Query.equal('id', lead.id)
          ]);
          var awLeadId;
          if (existingLeadResult.documents.length > 0) {
            console.log("Lead exists, updating with:");
          } else {
            console.log("Lead doesnt exist, creating");
            let leadAddResult = await db.createDocument('6606ae48b65524813d7d', leadsCollectionId, 'unique()', JSON.stringify(documentData));
            setUpdateStatus(`Creating lead: ${lead.Full_Name} (${companyName})`);
            console.log(leadAddResult.$id);
            awLeadId = leadAddResult.$id;
          }

          updateNotes(lead.id, awLeadId);
        } catch (error) {
          console.log("failed " + lead.id)
        }
        setUpdateStatus("Leads have been successfully synced.");
      }
    } catch (error) {
      console.error("Error syncing leads:", error);
      setUpdateStatus(`Error occurred during leads sync: ${error.message}`);
    }
    setIsUpdating(false);
  };

  const deleteAllRecords = async () => {
    setIsUpdating(true);
    setCurrentProcess("Deleting all records...");

    const db = new Databases(client);
    const collectionIds = ['66099411002504d32bb2', '6606f60c0019b772d53f', '66095f75001c8c22ca8f', '660826cf0002965e0238'];

    try {
      for (const collectionId of collectionIds) {
        let hasMore = true;
        while (hasMore) {
          const documents = await db.listDocuments('6606ae48b65524813d7d', collectionId, [Query.limit(100)]);
          hasMore = documents.documents.length > 0;

          for (const document of documents.documents) {
            await db.deleteDocument('6606ae48b65524813d7d', collectionId, document.$id);
            console.log("deleting: " + document.$id);
          }
        }
      }
      setCurrentProcess("All records in specified collections have been successfully deleted.");
    } catch (error) {
      console.error("Error deleting records:", error);
      setCurrentProcess(`Error occurred while deleting records: ${error.message}`);
    }

    setIsUpdating(false);
  };

  const syncEmails = async () => {
    setIsUpdating(true);
    setCurrentProcess("Syncing emails from leads...");

    const db = new Databases(client);
    const leadsCollectionId = '6606f60c0019b772d53f'; // Assuming this is your leads collection ID

    try {
      let currentPage = 1;
      let hasMoreLeads = true;

      while (hasMoreLeads) {
        const leadsResult = await db.listDocuments('6606ae48b65524813d7d', leadsCollectionId, [Query.limit(100), Query.offset((currentPage - 1) * 100)]);
        const leads = leadsResult.documents;
        hasMoreLeads = leads.length > 0;

        for (const lead of leads) {
          setCurrentProcess("Syncing emails from " + lead.Full_Name);
          try {
            var emailsData = null;
            console.log("Checking for emails for: " + lead.Full_Name)
            const getEmailsResponse = await fetch(`https://am01.coffeecup.solutions/webhook/GetEmails?zohoId=${lead.id}`);
            emailsData = await getEmailsResponse.json();

            for (const emailBatch of emailsData) {
              for (const email of emailBatch.Emails) {
                console.log("FOUND EMAIL " + email.subject + " for the lead " + lead.Full_Name);
                const messageResponse = await fetch(`https://am01.coffeecup.solutions/webhook/GetEmailContent?zohoId=${email.message_id}`);
                const messageData = await messageResponse.json();
              
                if (messageData.length > 0 && messageData[0].Emails && Array.isArray(messageData[0].Emails)) {
                  for (const message of messageData[0].Emails) {
                  console.log("GOT CONTENT FOR EMAIL " + message.from.email);
                 const checkExistence = await db.listDocuments('6606ae48b65524813d7d', '6614ef49003b1c93f3c2', [Query.equal('message_id', email.message_id)]);

                  if (checkExistence.documents.length === 0) {
                    const emailDocData = {
                      message_id: email.message_id,
                      OwnerName: message.from.user_name,
                      OwnerEmail: message.from.email,
                      SentTime: new Date(message.sent_time),
                      Subject: message.subject,
                      EmailBody: message.content,
                      EmailToEmail: message.to[0].email,
                      EmailToName: message.to[0].user_name,
                      zohoLeads: lead.$id
                    };

                    await db.createDocument('6606ae48b65524813d7d', '6614ef49003b1c93f3c2', 'unique()', emailDocData);
                    console.log(`Email ${email.message_id} added.`);
                  } else {
                    console.log(`Email ${email.message_id} already exists. Skipping.`);
                  }
                 
                  console.log('ON TO THE NEXT ONE');
                }
              }
            }
            console.log("Now on to the next lead")
            }
          } catch (error) {
            console.error("Failed to process emails for lead with ID: " + lead.id, error);
          }
        }

        currentPage++;
      }

      setCurrentProcess("Email sync complete.");
    } catch (error) {
      console.error("Error syncing emails:", error);
      setCurrentProcess(`Error occurred during email sync: ${error.message}`);
    }

    setIsUpdating(false);
  };

  return (
    <div className="space-y-4 p-4">
      <h2 className="text-xl font-semibold">Settings</h2>
      <button className={`p-2 text-white bg-blue-500 hover:bg-blue-700 font-bold rounded disabled:bg-gray-500`} onClick={triggerWebhooksForAllCompanies} disabled={isUpdating}>
        {isUpdating ? 'Processing...' : 'Update All Leads'}
      </button>
      <button className={`ml-4 p-2 text-white bg-green-500 hover:bg-green-700 font-bold rounded disabled:bg-gray-500`} onClick={triggerGetCallsAndUpdate} disabled={isUpdating}>
        {isUpdating ? 'Processing...' : 'Update Calls'}
      </button>

      <button className={`ml-4 p-2 text-white bg-orange-500 hover:bg-orange-700 font-bold rounded disabled:bg-gray-500`} onClick={checkCompanies} disabled={isUpdating}>
        {isUpdating ? 'Processing...' : 'Check Companies'}
      </button>
      <button className="ml-4 bg-blue-500 p-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-500" onClick={SyncLeads} disabled={isUpdating}>
        {isUpdating ? 'Processing...' : 'Sync Leads'}
      </button>
      <button
        className="ml-4 p-2 text-white bg-red-500 hover:bg-red-700 font-bold rounded disabled:bg-gray-500"
        onClick={deleteAllRecords}
        disabled={isUpdating}>
        {isUpdating ? 'Processing...' : 'Delete All Records'}
      </button>

      <button className={`ml-4 p-2 text-white bg-purple-500 hover:bg-purple-700 font-bold rounded disabled:bg-gray-500`} onClick={syncEmails} disabled={isUpdating}>
        {isUpdating ? 'Processing...' : 'Sync Emails'}
      </button>

      <div>
        {updateStatus && <p>{updateStatus}</p>}
        {currentProcess && <p>{currentProcess}</p>}
      </div>
    </div>
  );
}

export default Settings;