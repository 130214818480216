import React, { useEffect, useState } from "react";
import { Databases, Query } from "appwrite";
import { client } from "./appwriteConfig";
import { useNavigate } from "react-router-dom";

function Pagination({ total, current, onChange }) {
  const items = [];
  const leftSide = Math.max(1, current - 2);
  const rightSide = Math.min(total, current + 2);
  

  if (leftSide > 1) {
    items.push(
      <button key="first" onClick={() => onChange(1)} className="px-3 py-2 bg-white text-blue-500 hover:bg-blue-500 hover:text-white rounded">1</button>,
      <span key="firstEllipsis" className="px-3 py-2">...</span>
    );
  }

  for (let number = leftSide; number <= rightSide; number++) {
    items.push(
      <button
        key={number}
        onClick={() => onChange(number)}
        className={`px-3 py-2 rounded ${current === number ? "bg-blue-700 text-white" : "bg-white text-blue-500 hover:bg-blue-500 hover:text-white"}`}
      >
        {number}
      </button>
    );
  }

  if (rightSide < total) {
    items.push(
      <span key="lastEllipsis" className="px-3 py-2">...</span>,
      <button key="last" onClick={() => onChange(total)} className="px-3 py-2 bg-white text-blue-500 hover:bg-blue-500 hover:text-white rounded">{total}</button>
    );
  }

  return (
    <div className="flex items-center justify-center space-x-2 my-4">
      <button onClick={() => onChange(Math.max(1, current - 1))} disabled={current === 1} className="px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300">Prev</button>
      {items}
      <button onClick={() => onChange(Math.min(total, current + 1))} disabled={current === total} className="px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300">Next</button>
    </div>
  );
}

function MyLeads() {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(""); 
  const companiesPerPage = 10;
  const navigate = useNavigate();
  const databases = new Databases(client);
  const users = [
    { name: "Jonny Hood", id: "228105000010526001" },
    { name: "Nick Church", id: "228105000008677001" },
    { name: "Tom Beech", id: "228105000000183009" },
    { name: "Lucy Brannan", id: "228105000021013001" },
    { name: "Sam Maley", id: "228105000021978001" },
    { name: "Hayden Trott", id: "228105000000259011" },
    { name: "Steve Watson", id: "228105000001781019" }
  ];


  useEffect(() => {
    async function fetchCompanies() {
      const queries = [Query.offset((currentPage - 1) * companiesPerPage), Query.limit(companiesPerPage)];
      
      if (searchTerm) {
        queries.push(Query.search('CompanyName', searchTerm));
      }
      
      if (selectedUserId) {
        queries.push(Query.equal("Owner_Id", selectedUserId));
      }

      try {
        const result = await databases.listDocuments("6606ae48b65524813d7d", "66095f75001c8c22ca8f", queries);
        setCompanies(result.documents);
        setTotalCompanies(result.total);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    }
    fetchCompanies();
  }, [currentPage, searchTerm, selectedUserId]);

  return (
    <div>
    <h1 className="text-xl font-semibold mb-4">Companies</h1>
    <div className="flex justify-between items-center mb-4">
      <input
        type="text"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        placeholder="Search companies..."
        className="p-2 border rounded w-1/4 "
      />
      <select
        onChange={e => setSelectedUserId(e.target.value)}
        className="p-2 border rounded w-1/4 "
        defaultValue=""
      >
        <option value="" disabled>Select a user</option>
        {users.map(user => (
          <option key={user.id} value={user.id}>{user.name}</option>
        ))}
      </select>
    </div>
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
          <thead className="bg-slate-300">
            <tr>
              <th className="px-4 py-2 text-left">Company Name</th>
              <th className="px-4 py-2 text-left">Contacts</th>
              <th className="px-4 py-2 text-left">View</th>
            </tr>
          </thead>
          <tbody>
            {companies.map(company => (
              <tr key={company.$id} className="bg-white border-b">
                <td className="px-4 py-2">{company.CompanyName}</td>
                <td className="px-4 py-2">
                  {company.zohoLeads.map(lead => (
                    <span key={lead.$id} className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">{lead.Full_Name}</span>
                  ))}
                </td>
                <td className="px-4 py-2">
                  <button
                    onClick={() => navigate(`/view-company/${company.$id}`)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination total={Math.ceil(totalCompanies / companiesPerPage)} current={currentPage} onChange={setCurrentPage} />
      </div>
    </div>
  );
}

export default MyLeads;