import React, { useState } from 'react';
import { account } from './appwriteConfig';
import { OAuthProvider } from 'appwrite';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await account.createEmailPasswordSession(email, password);
      onLogin(true);
    } catch (error) {
      console.error('Error during login:', error.message);
      onLogin(false);
    }
  };
  const baseUrl = window.location.origin;
  const handleMicrosoftLogin = async () => {
    try {
      account.createOAuth2Session(OAuthProvider.Microsoft, 
        `${baseUrl}/myleads`,
        `${baseUrl}/`,
      [] 
    );
    } catch (error) {
      console.error('Error during OAuth login:', error.message);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-full max-w-xs">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="mb-6 text-center text-2xl font-bold">Login</h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input onChange={(e) => setEmail(e.target.value)} value={email} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email"/>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input onChange={(e) => setPassword(e.target.value)} value={password} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************"/>
          </div>
          <div className="flex items-center justify-between">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Sign In
          </button>

          <button 
            onClick={handleMicrosoftLogin} 
            type="button" 
            className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Login with M365
          </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;