import React, { createContext, useContext, useState, useEffect } from 'react';
import { account } from './appwriteConfig';
import { USER_DETAILS } from './appwriteConfig'; 
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState(() => {
    const saved = localStorage.getItem(USER_DETAILS);
    if (saved) {
      return JSON.parse(saved);
    }
    return {
      isAuthenticated: false,
      userEmail: null,
    };
  });

  useEffect(() => {
    localStorage.setItem(USER_DETAILS, JSON.stringify(userDetails));
  }, [userDetails]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        await account.getSession('current');
        const details = await account.get();
        setUserDetails({
          isAuthenticated: true,
          userEmail: details.email,
        });
      } catch (error) {
        setUserDetails(prevDetails => ({
          ...prevDetails,
          isAuthenticated: false
        }));
      }
    };

    if (!userDetails.isAuthenticated) { 
      checkSession();
    }
  }, []);

  return <UserContext.Provider value={userDetails}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);