import React, { useEffect, useState } from 'react';
import { Databases } from 'appwrite'; // Make sure to import Databases
import { client } from './appwriteConfig';
import { account } from './appwriteConfig';
import { useUser } from './UserContext';
import { USER_DETAILS } from './appwriteConfig'; 

const TopBar = () => {
  const { setUserDetails } = useUser();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(() => localStorage.getItem('selectedProduct') || '');

  useEffect(() => {
    const fetchProducts = async () => {
      const db = new Databases(client);
      try {
        const result = await db.listDocuments('6606ae48b65524813d7d', '660abd18002b4bab15c6');
        setProducts(result.documents);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleLogout = async () => {
    try {
      await account.deleteSession('current');
      localStorage.removeItem(USER_DETAILS);
      setUserDetails({
        isAuthenticated: false,
        userEmail: null,
      });
    } catch (error) {
      console.error('Logout failed', error.message);
    }
  };

  const handleProductChange = (e) => {
    const productId = e.target.value;
    localStorage.setItem('selectedProduct', productId);
    setSelectedProduct(productId);
  };

  return (
    <div className="bg-slate-700 text-white p-4 flex justify-between items-center">
    <h1 className="text-lg"></h1>
    {/* Wrapper div for the dropdown */}
    <div className="w-64"> {/* Adjust the width as needed */}
      <select 
        value={selectedProduct} 
        onChange={handleProductChange}
        className="block w-full px-2 py-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        aria-label="Select a product">
        <option value="">Select a product</option>
        {products.map((product) => (
          <option key={product.$id} value={product.$id}>{product.Name}</option>
        ))}
      </select>
    </div>
    <button
      onClick={handleLogout}
      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
    >
      Logout
    </button>
  </div>
  );
};

export default TopBar;