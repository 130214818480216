import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Databases, Query } from "appwrite";
import { account, client } from "./appwriteConfig";
import ViewLead from "./ViewLead";
import {
  FiMail,
  FiPhone,
  FiActivity,
  FiPhoneCall,
  FiCalendar,
  FiInfo,
  FiBriefcase,
  FiHelpCircle,
  FiPlus,
  FiFileText,
} from "react-icons/fi";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ViewCompany() {
  const { id } = useParams();
  const navigate = useNavigate();
  const query = useQuery();
  const method = query.get("method");
  const [company, setCompany] = useState({ zohoLeads: [] });
  const [notes, setNotes] = useState([]);
  const [calls, setCalls] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);

  const [loadingNavigation, setLoadingNavigation] = useState(true);
  const [isCallMode, setIsCallMode] = useState(
    localStorage.getItem("CallMode") === "true"
  );
  const [selectedProduct, setSelectedProduct] = useState(() =>
    localStorage.getItem("selectedProduct")
  );
  const databases = new Databases(client);
  const { id: companyId } = useParams();
  const [activeTab, setActiveTab] = useState("information");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLeadId, setCurrentLeadId] = useState(null);

  function AddActionModal({ isOpen, onClose, onSave }) {
    const [note, setNote] = useState("");

    const handleSave = (label) => {
      var payload = [];
      if (label === "note") {
        payload = {
          Note_Title: account.Name,
          Note_Content: note.trim(),
          se_module: "Leads",
          ZohoLeadId: currentLeadId,
        };
        console.log("Saving note:", note);
      } else if (label === "noAnswer") {
        console.log('Processing "Called, no answer" for:', note);
        payload = {
          Note_Title: account.Name,
          Note_Content: "Called, no answer",
          se_module: "Leads",
          ZohoLeadId: currentLeadId,
        };
      } else if (label === "gatekeeper") {
        console.log('Processing "Called, gatekeeper" for:', note);
        payload = {
          Note_Title: account.Name,
          Note_Content: "Called, stuck with gatekeeper",
          se_module: "Leads",
          ZohoLeadId: currentLeadId,
        };
      }

      fetch("https://am01.coffeecup.solutions/webhook/add-note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) throw new Error("Network response was not ok");
          return response.json();
        })
        .then((data) => {
          console.log("Note added successfully", data);

          onClose();
        })
        .catch((error) => {
          console.error("Error adding note:", error);
        });

      onSave({ note, label });
      onClose();
    };

    return (
      <div
        className={`${
          isOpen ? "fixed" : "hidden"
        } inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50`}
        onClick={onClose}
      >
        <div
          className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mb-4">
            <h2 className="text-xl font-semibold">Add Note</h2>
            <button
              onClick={onClose}
              className="float-right pt-2 pr-2 text-lg font-semibold"
            >
              X
            </button>
            <textarea
              className="w-full h-40 p-2 border rounded resize-none"
              placeholder="Notes"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={() => handleSave("note")}
            >
              Add Note
            </button>
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={() => handleSave("noAnswer")}
            >
              Called, no answer
            </button>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleSave("gatekeeper")}
            >
              Called, gatekeeper
            </button>
          </div>
        </div>
      </div>
    );
  }

  const openLeadModal = (leadId) => {
    console.log("opening modal");
    setCurrentLeadId(leadId);
    setIsModalOpen(true);
  };

  const [isActionModalOpen, setIsActionModalOpen] = useState(false);

  const openActionModal = (lead) => {
    console.log(lead);
    setCurrentLeadId(lead);

    setIsActionModalOpen(true);
    console.log(currentLeadId);
  };
  const closeActionModal = () => {
    setIsActionModalOpen(false);
  };
  const handleSaveAction = (data) => {
    // Process action data here (e.g., sending to API)
    console.log(data);
  };
  useEffect(() => {
    const fetchCompaniesAndDetails = async () => {
      try {
        let companyResult;
        console.log(method);
        console.log(id);
        if (method === "popup") {
          console.log("Noting popup");
          const leadsResponse = await databases.listDocuments(
            "6606ae48b65524813d7d",
            "6606f60c0019b772d53f",
            [Query.equal("id", id)]
          );
          console.log("HERE");
          console.log(leadsResponse);
          if (leadsResponse.documents.length === 0) {
            throw new Error("Lead not found.");
          }
          const lead = leadsResponse.documents[0];
          console.log(lead);
          companyResult = await databases.getDocument(
            "6606ae48b65524813d7d",
            "66095f75001c8c22ca8f",
            lead.zohoLeadCompanies.$id
          );
        } else {
          companyResult = await databases.getDocument(
            "6606ae48b65524813d7d",
            "66095f75001c8c22ca8f",
            id
          );
        }
        setCompany(companyResult);

        let allNotes = [],
          allEmails = [],
          allCalls = [];
        for (let lead of companyResult.zohoLeads) {
          let notesResult = await databases.listDocuments(
            "6606ae48b65524813d7d",
            "660826cf0002965e0238",
            [Query.equal("LeadId", lead.id)]
          );
          allNotes.push(
            ...notesResult.documents.map((note) => ({
              ...note,
              contactName: lead.Full_Name,
              ModifiedTime: new Date(note.ModifiedTime),
              type: "note",
            }))
          );
          let mappedCalls = lead.zohoLeadCalls.map((call) => ({
            ...call,
            contactName: lead.Full_Name,
            Created_Time: new Date(call.Created_Time),
          }));
          allCalls.push(...mappedCalls);
          if (lead.zohoLeadEmails.length > 0) {
            allEmails.push(
              ...lead.zohoLeadEmails.map((email) => ({
                ...email,
                contactName: lead.Full_Name,
                SentTime: new Date(email.SentTime),
                OwnerName: email.Subject,
                NoteText: email.EmailBody,
                type: "email",
              }))
            );
          }
        }
        // Combine notes and emails, then sort by their time (ModifiedTime for notes, SentTime for emails)
        const combinedItems = [...allNotes, ...allEmails];
        combinedItems.sort((a, b) => {
          let timeA = a.ModifiedTime || a.SentTime;
          let timeB = b.ModifiedTime || b.SentTime;
          return new Date(timeB) - new Date(timeA);
        });

        allCalls.sort((a, b) => b.Created_Time - a.Created_Time);
        setCalls(allCalls);
        setNotes(combinedItems); // Assuming you will reinterpret 'notes' as the combined list for the UI representation
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    if (!isActionModalOpen) {
      console.log("waiting to refresh");
      const timeout = setTimeout(() => {
        fetchCompaniesAndDetails();
      }, 3000);
    }

    fetchCompaniesAndDetails();
  }, [id, method, isActionModalOpen]);

  useEffect(() => {
    const fetchNavigationData = async () => {
      try {
        let result = await databases.listDocuments(
          "6606ae48b65524813d7d",
          "66095f75001c8c22ca8f",
          [Query.limit(100)]
        );
        setAllCompanies(result.documents);
        setLoadingNavigation(false);
      } catch (error) {
        console.error("Error fetching navigation data:", error);
        setLoadingNavigation(false);
      }
    };

    fetchNavigationData();
  }, []);

  function Tab({ title, icon, active, onClick }) {
    return (
      <div
        className={`cursor-pointer py-2 px-4 flex items-center ${
          active ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-600"
        }`}
        onClick={onClick}
      >
        {icon}
        <span className="ml-2">{title}</span>
      </div>
    );
  }

  function InformationTab({ company, notes, calls, onOpenLeadModal }) {
    function sanitizeAndFormatHtml(html) {
      // Remove CSS styles and HTML comments first
      html = html
        .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
        .replace(/<!--[\s\S]*?-->/g, "")
        .replace(/<script[^>]*>[\s\S]*?<\/script>/gi, "")
        .replace(/@media[^{]+\{([\s\S]+?\})\s*\}/gi, "")
        .replace(/<br\s*[\/]?>/gi, "\n\n")
        .replace(/<\/?[^>]+(>|$)/g, "") // Remove remaining HTML tags
        .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec)) // Decode HTML entities
        .replace(/&quot;/g, '"')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");

      // Replace multiple newline characters with a single <br>. This regex matches 2 or more.
      html = html.replace(/\n{2,}/g, "<br>");
      return html;
    }

    return (
      <div className="flex flex-wrap">
        <div className="w-full lg:w-3/12 p-4">
          <h2 className="text-xl font-semibold">Contacts</h2>
          {company.zohoLeads.length === 0 ? (
            <div className="mt-2 p-8 border-l-4 border-orange-500 bg-orange-50 rounded text-center">
              <p className="text-gray-400">No Contacts</p>
            </div>
          ) : (
            company.zohoLeads.map((lead, index) => (
              <div
                key={index}
                className="mt-2 p-2 border-l-4 border-orange-500 bg-orange-50 rounded cursor-pointer relative"
              >
                <div onClick={() => openLeadModal(lead.$id)}>
                  <p className="font-semibold">{lead.Full_Name}</p>

                  {lead.Email && (
                    <div className="flex items-center mb-2">
                      <FiMail className="mr-2 text-orange-500" />
                      <a
                        href={`mailto:${lead.Email}`}
                        onClick={(e) => e.stopPropagation()}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        {lead.Email}
                      </a>
                    </div>
                  )}

                  {lead.Phone && (
                    <div className="flex items-center mb-2">
                      <FiPhone className="mr-2 text-orange-500" />
                      <a
                        href={`tel:${lead.Phone}`}
                        onClick={(e) => e.stopPropagation()}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        {lead.Phone}
                      </a>
                    </div>
                  )}

                  {(lead.Lead_Source || lead.Lead_Stage) && (
                    <div className="flex items-center mb-2">
                      <FiInfo className="mr-2 text-orange-500" />
                      <span className="text-sm">{lead.Lead_Source}</span>
                      {lead.Lead_Source && lead.Lead_Stage && (
                        <span> &nbsp; </span>
                      )}
                      <span className="text-sm">{lead.Lead_Stage}</span>
                    </div>
                  )}
                </div>

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    openActionModal(lead.id);
                  }}
                  className="absolute bottom-1 right-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded flex m-2 items-center text-xs"
                >
                  <FiFileText size={16} />
                </button>
              </div>
            ))
          )}
        </div>

        {/* Notes */}
        <div className="w-full lg:w-6/12 p-4">
          <h2 className="text-xl font-semibold">Notes & Emails</h2>

          {notes.length === 0 ? (
            <div className="mt-2 p-14 border-l-4 border-blue-500 bg-blue-50 rounded text-center">
              <p className="text-gray-400">No Notes or Emails</p>
            </div>
          ) : (
            notes.map((item, index) => (
              <div
                key={index}
                className={`mt-2 p-2 border-l-4 ${
                  item.type === "note"
                    ? "border-blue-500 bg-blue-50"
                    : "border-purple-500 bg-purple-50"
                } rounded`}
              >
                <p>
                  <span className="font-semibold">
                    {item.OwnerName || "Unknown"}:
                  </span>{" "}
                  <span>{item.contactName}</span>
                </p>
                <div>
                  {item.type === "email" ? (
                    <div>
                      <p>
                        <small>
                          <i>
                            {item.OwnerEmail} emailed {item.EmailToEmail}
                          </i>{" "}
                        </small>
                      </p>
                      <div
                        className="emailContent"
                        dangerouslySetInnerHTML={{
                          __html: sanitizeAndFormatHtml(item.NoteText),
                        }}
                      ></div>
                    </div>
                  ) : (
                    <p>{item.NoteText}</p>
                  )}
                </div>

                <span className="text-sm text-gray-500">
                  {new Date(
                    item.ModifiedTime || item.SentTime
                  ).toLocaleDateString()}
                </span>
              </div>
            ))
          )}
        </div>

        {/* Calls */}
        <div className="w-full lg:w-3/12 p-4">
          <h2 className="text-xl font-semibold">Calls</h2>
          {calls.length === 0 ? (
            <div className="mt-2 p-8 border-l-4 border-green-500 bg-green-50 rounded text-center">
              <p className="text-gray-400">No Calls</p>
            </div>
          ) : (
            calls.map((call, index) => (
              <div
                key={index}
                className="mt-2 p-2 border-l-4 border-green-500 bg-green-50 rounded"
              >
                <p>
                  <span className="font-semibold">{call.Caller_Name}</span> &{" "}
                  <span>{call.contactName}</span>
                </p>

                <p className="flex items-center">
                  <FiPhoneCall className="mr-1 text-green-600" />{" "}
                  <span>{call.Call_Duration}</span>
                </p>

                <p className="flex items-center">
                  <FiActivity className="mr-1 text-green-600" />
                  <span>
                    {Math.floor(
                      (new Date() - new Date(call.Created_Time)) /
                        (1000 * 60 * 60 * 24)
                    )}{" "}
                    days ago
                  </span>
                </p>

                <p className="flex items-center">
                  <FiCalendar className="mr-1 text-green-600" />{" "}
                  <span className="text-sm">
                    {new Date(call.Created_Time).toLocaleString()}
                  </span>
                </p>
              </div>
            ))
          )}
        </div>

        <AddActionModal
          isOpen={isActionModalOpen}
          onClose={closeActionModal}
          onSave={handleSaveAction}
        />
      </div>
    );
  }

  function ProductHelpTab() {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeIntroTab, setActiveIntroTab] = useState(0);
    const selectedProduct = localStorage.getItem("selectedProduct");

    useEffect(() => {
      if (selectedProduct) {
        setLoading(true);
        const db = new Databases(client);
        db.getDocument(
          "6606ae48b65524813d7d",
          "660abd18002b4bab15c6",
          selectedProduct
        )
          .then((result) => {
            setProduct(result);
          })
          .catch((error) => {
            console.error("Error fetching product:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, [selectedProduct]);

    if (loading) {
      return <div>Loading product information...</div>;
    }

    if (!product) {
      return <div>Product information not available</div>;
    }

    const IntroTabs = () => (
      <div>
        <div className="w-1/8 p-4">
          <div className="flex flex-col">
            {product.productIntros.map((intro, index) => (
              <button
                key={index}
                className={`py-2 px-3 mb-2 rounded ${
                  index === activeIntroTab
                    ? "bg-slate-800 text-white"
                    : "bg-gray-200 text-gray-800"
                } transition duration-300 ease-in-out`}
                onClick={() => setActiveIntroTab(index)}
              >
                {intro.Name}
              </button>
            ))}
          </div>
        </div>
      </div>
    );

    const renderTable = (items, title) => (
      <>
        <h2 className="text-xl font-semibold mt-6 mb-2">{title}</h2>
        <table className="w-full text-left">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 font-medium">Subject</th>
              <th className="px-4 py-2 font-medium">Detail</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2">
                  {item.Subject ?? item.Keyword ?? item.Name ?? item.Question}
                </td>
                <td className="px-4 py-2">
                  {item.Hint ?? item.Introduction ?? item.Answers}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );

    return (
      <div>
        <h2 className="text-xl font-semibold ">Routes In</h2>

        <div className="container mx-auto p-4 flex">
          <IntroTabs />
          <div className="w-3/4 p-">
            <div className="prose">
              {product.productIntros[activeIntroTab]?.Introduction}
            </div>
          </div>
        </div>

        {product.productHints &&
          renderTable(product.productHints, "Product Hints")}
        {product.productQuestions &&
          renderTable(product.productQuestions, "Product Questions")}
      </div>
    );
  }
  const findCompanyIndex = () =>
    allCompanies.findIndex((company) => company.$id === id);
  const prevCompanyId =
    findCompanyIndex() > 0 ? allCompanies[findCompanyIndex() - 1].$id : null;
  const nextCompanyId =
    findCompanyIndex() + 1 < allCompanies.length
      ? allCompanies[findCompanyIndex() + 1].$id
      : null;

  return (
    <>
      <div className="flex justify-between mb-4 items-center">
        {!method && ( // Condition to check if "method" query param is NOT "popup"
          <>
            <button
              disabled={loadingNavigation}
              onClick={() => navigate(`/view-company/${prevCompanyId}`)}
              className={`text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded ${
                loadingNavigation ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              &#8592; Previous
            </button>
            <h1 className="text-2xl font-bold">
              {company.CompanyName}{" "}
              <span className="font-normal">({company.Owner_Name})</span>
            </h1>
            <button
              disabled={loadingNavigation}
              onClick={() => navigate(`/view-company/${nextCompanyId}`)}
              className={`text-white bg-green-500 hover:bg-green-700 font-bold py-2 px-4 rounded ${
                loadingNavigation ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Next &#8594;
            </button>
          </>
        )}
        {method === "popup" && ( // Conditionally render the title only when "method" is "popup"
          <h1 className="text-2xl font-bold">
            {company.CompanyName}{" "}
            <span className="font-normal">({company.Owner_Name})</span>
          </h1>
        )}
      </div>
      <div className="flex space-x-4 border-b mb-4">
        <Tab
          title="Information"
          icon={<FiBriefcase />}
          active={activeTab === "information"}
          onClick={() => setActiveTab("information")}
        />
        <Tab
          title="Product Help"
          icon={<FiHelpCircle />}
          active={activeTab === "productHelp"}
          onClick={() => setActiveTab("productHelp")}
        />
      </div>

      {activeTab === "information" && (
        <InformationTab
          company={company}
          notes={notes}
          calls={calls}
          onOpenLeadModal={openLeadModal}
        />
      )}
      {activeTab === "productHelp" && <ProductHelpTab />}

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mb-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="float-right pt-2 pr-2 text-lg font-semibold"
              >
                X
              </button>
            </div>
            <ViewLead leadId={currentLeadId} />
          </div>
        </div>
      )}
    </>
  );
}

export default ViewCompany;
