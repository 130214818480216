import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Databases, Query } from 'appwrite';
import { client } from './appwriteConfig';

function ViewLead({ leadId }) {
  console.log(leadId);
  const { id } = useParams();
  const effectiveLeadId = leadId || id;
  const navigate = useNavigate();
  const [lead, setLead] = useState({});
  const [notes, setNotes] = useState([]);
  const [similarCompanies, setSimilarCompanies] = useState([]);

  useEffect(() => {
    const databases = new Databases(client);

    const fetchLead = async () => {
      try {
        const result = await databases.getDocument('6606ae48b65524813d7d', '6606f60c0019b772d53f', effectiveLeadId);
        setLead(result);
        triggerWebhook(effectiveLeadId);
        fetchNotes(result.id);
        fetchSimilarCompanies(result.Company);
      } catch (error) {
        console.error("Error fetching lead:", error);
      }
    };

    const fetchNotes = async (leadId) => {
      try {
        const results = await databases.listDocuments('6606ae48b65524813d7d', '660826cf0002965e0238', [
          Query.equal('LeadId', leadId),
          Query.orderDesc('ModifiedTime')
        ]);
        setNotes(results.documents);
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    };

    const fetchSimilarCompanies = async (companyName) => {
      try {
        const result = await databases.listDocuments('6606ae48b65524813d7d', '6606f60c0019b772d53f', [
          Query.equal('Company', companyName),
          Query.notEqual('$id', effectiveLeadId),
        ]);
        setSimilarCompanies(result.documents);
      } catch (error) {
        console.error("Error fetching similar companies:", error);
      }
    };

    fetchLead();
  }, [id]);

  const triggerWebhook = async (leadId) => {
    try {
      await fetch(`https://am01.coffeecup.solutions/webhook/UpdateLeadNotes?id=${leadId}`);
    } catch (error) {
      console.error("Error triggering webhook:", error);
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-8/12 p-5">
        <h1 className="text-3xl font-semibold mb-4">{lead.Full_Name}</h1>
        <div className="flex mb-8">
          <div className="bg-gray-100 p-6 rounded-lg shadow w-1/2 mr-2">
            <h2 className="text-xl font-medium mb-4">Contact Information</h2>
            <p><strong>Email:</strong> {lead.Email}</p>
            <p><strong>Phone:</strong> {lead.Phone}</p>
            <p><strong>Mobile:</strong> {lead.Mobile}</p>
            <p>
              <strong>Company: </strong>
              <span
                className="cursor-pointer text-blue-600 hover:text-blue-800"
                onClick={() => navigate(`/view-company/${lead.zohoLeadCompanies.$id}`)}
              >
                {lead.Company}
              </span>
            </p>
          </div>
  
          <div className="bg-gray-100 p-6 rounded-lg shadow w-1/2 ml-2">
            <h2 className="text-xl font-medium mb-4">Lead Details</h2>
            <p><strong>Lead Source:</strong> {lead.Lead_Source}</p>
            <p><strong>Lead Stage:</strong> {lead.Lead_Stage}</p>
            <p><strong>Industry:</strong> {lead.Industry}</p>
            <p><strong>Designation:</strong> {lead.Designation}</p>
          </div>
        </div>
  
        <h2 className="text-2xl font-semibold mb-4">Notes</h2>
        <ul className="bg-gray-100 p-6 rounded-lg shadow space-y-4 mb-6">
          {notes.map((note, index) => (
            <li key={index} className="border-l-4 border-blue-500 pl-4">
              <h3 className="font-semibold">{note.NoteTitle}</h3>
              <p>{note.NoteText}</p>
              <span className="text-sm text-gray-500">By {note.OwnerName} on {new Date(note.ModifiedTime).toLocaleDateString()}</span>
            </li>
          ))}
        </ul>
      </div>
  
      <div className="w-full lg:w-4/12 p-5">
        <h2 className="text-2xl font-semibold mb-4">Other Contacts</h2>
        <ul className="bg-gray-100 p-6 rounded-lg shadow space-y-4 mb-6">
          {similarCompanies.map((company, index) => (
            <li key={index} className="border-l-4 border-green-500 pl-4 cursor-pointer" onClick={() => navigate(`/view-lead/${company.$id}`)}>
              <h3 className="font-semibold">{company.Full_Name}</h3>
              <p>{company.Company}</p>
              <p>{company.Email}</p>
            </li>
          ))}
        </ul>
        <div className="bg-gray-100 p-6 rounded-lg shadow">
          <h2 className="text-xl font-medium mb-4">Additional Information</h2>
          <p><strong>Description:</strong> {lead.Description || 'N/A'}</p>
          <p><strong>Website:</strong> {lead.Website || 'N/A'}</p>
          <p><strong>No of Employees:</strong> {lead.No_of_Employees || 'N/A'}</p>
          <p><strong>Annual Revenue:</strong> {lead.Annual_Revenue || 'N/A'}</p>
          <p><strong>Outsourced IT:</strong> {lead.Outsourced_IT ? 'Yes' : 'No'}</p>
        </div>
      </div>
      
    </div>
  );
}

export default ViewLead;