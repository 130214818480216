import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FiHome, FiList, FiMenu, FiTool, FiX, FiTrendingUp } from 'react-icons/fi';
import { PiRobot } from 'react-icons/pi';
import { useLayout } from './LayoutContext';

const NavItem = ({ to, icon, label, activeWhen }) => {
  const { isNavExpanded } = useLayout();
  const location = useLocation();

  const isActiveLogic = () => {
    const basePath = location.pathname.split('?')[0];
    let isActive = basePath === to;
    
    if (!isActive && activeWhen) {
      isActive = activeWhen.some(pattern => basePath.startsWith(pattern));
    }
    
    return `flex items-center space-x-2 py-2 px-4 rounded hover:bg-slate-600 ${isActive ? 'bg-slate-500' : ''}`;
  };

  return (
    <NavLink to={to} className={isActiveLogic}>
      <span>{icon}</span>
      {isNavExpanded && <span>{label}</span>}
    </NavLink>
  );
};

const Nav = () => {
  const { isNavExpanded, setIsNavExpanded } = useLayout();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const method = queryParams.get('method');
  if (method === 'popup') {
    setIsNavExpanded(false)
    return null;
  }

  return (
    <div className={`bg-slate-700 text-white h-full fixed top-0 left-0 p-5 transition-all ease-in-out duration-300 ${isNavExpanded ? 'w-64' : 'w-16'} lg:w-64`}>
      <button onClick={() => setIsNavExpanded(!isNavExpanded)} className="p-2 mb-5 lg:hidden">
        {isNavExpanded ? <FiX size={24} /> : <FiMenu size={24} />}
      </button>

      <ul className="mt-6">
        {isNavExpanded && <h1 className="text-xl mb-5">Sales-o-matic</h1>}
        <NavItem to="/" icon={<FiHome />} label="Home" />
        <NavItem to="/myleads" icon={<FiTrendingUp />} label="Leads" activeWhen={['/view-company/', '/view-lead/']} />
        <NavItem to="/products" icon={<FiList />} label="Products" activeWhen={['/view-product/']} />
        <NavItem to="/documents" icon={<PiRobot />} label="Documents" activeWhen={['/documents/', '/view-document/']} />
        <NavItem to="/settings" icon={<FiTool />} label="Settings" />
      </ul>
    </div>
  );
};

export default Nav;