import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Databases } from 'appwrite';
import { client } from './appwriteConfig';

function Documents() {
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();
  const databases = new Databases(client);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const result = await databases.listDocuments('6606ae48b65524813d7d', '663b6cd0001c437f8c19');
        setDocuments(result.documents);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-4">Documents</h1>
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
            <thead className="bg-slate-300">
              <tr>
                <th className="px-4 py-2 text-left">Title</th>
        
                <th className="px-4 py-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {documents.map(document => (
                <tr key={document.$id} className="bg-white border-b">
                  <td className="px-4 py-2">{document.Title}</td>
           
                  <td className="px-4 py-2">
                    <button
                      onClick={() => navigate(`/view-document/${document.$id}`)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>
    </div>
  );
}

export default Documents;