import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Databases } from 'appwrite';
import { client } from './appwriteConfig';

function ViewProduct() {
    const { id } = useParams();
    
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ type: '', id: '', data: {} });
    const [refresh, setRefresh] = useState(false); // For triggering re-fetch

    useEffect(() => {
        const db = new Databases(client);
        const fetchProduct = async () => {
            try {
                const result = await db.getDocument('6606ae48b65524813d7d', '660abd18002b4bab15c6', id);
                setProduct(result);
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [id, refresh]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!product) {
        return <div>Product not found.</div>;
    }

    function formatDescription(description) {
        const paragraphs = description.split(/\n\n/);
        return paragraphs.map((paragraph, index) => (
            <p key={index} className="mb-4">{paragraph}</p>
        ));
    }

    const handleShowModal = (type, item) => {
        setFormData({ type, id: item?.$id ?? '', data: item ?? {} });
        setShowModal(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, data: { ...prev.data, [name]: value } }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const db = new Databases(client);
        const collectionId = {
            productIntros: '660ac8520030dd7dcd39',
            productKeywords: '660ac4cf0002d277b238',
            productQuestions: '660abeb0000967a9f0d7',
            productHints: '660abd79003e2eaf0def',
        }[formData.type];
    
        const documentData = (({ Name, Introduction, Keyword, Subject, Hint, HintType, Question, Answers }) => ({
            Name, Introduction, Keyword, Subject, Hint, HintType, Question, Answers
        }))(formData.data);
    
        try {
            if (formData.id) {
                await db.updateDocument('6606ae48b65524813d7d', collectionId, formData.id, documentData);
            } else {
                const payload = {
                    ...documentData,
                    products: id, 
                };
                await db.createDocument('6606ae48b65524813d7d', collectionId, 'unique()', payload);
            }
    
            setRefresh((r) => !r); 
            setShowModal(false); 
        } catch (error) {
            console.error('Error on form submit:', error);
        }
    };

    const renderModalForm = () => {
        const inputFields = {
            productIntros: [{ label: 'Name', name: 'Name' }, { label: 'Introduction', name: 'Introduction' }],
            productKeywords: [{ label: 'Keyword', name: 'Keyword' }],
            productHints: [{ label: 'Subject', name: 'Subject' }, { label: 'Hint', name: 'Hint' }, { label: 'Hint Type', name: 'HintType' }],
            productQuestions: [{ label: 'Question', name: 'Question' }, { label: 'Answers', name: 'Answers' }],
        }[formData.type] || [];

        return showModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-5 rounded">
                    <button className="absolute top-0 right-0 m-2" onClick={() => setShowModal(false)}>[Close]</button>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {inputFields.map((field, index) => (
                            <div key={index} className="flex flex-col">
                                <label htmlFor={field.name} className="text-sm font-semibold">{field.label}</label>
                                <input
                                    type="text"
                                    name={field.name}
                                    id={field.name}
                                    value={formData.data[field.name] || ''}
                                    onChange={handleChange}
                                    className="mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                        ))}
                        <div className="flex justify-end">
                            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    const renderTable = (items, title, type) => (
        <>
            <div className="flex justify-between items-center py-2">
                <h2 className="text-xl font-semibold">{title}</h2>
                <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handleShowModal(type, null)}
                >
                    Add New
                </button>
            </div>

            <table className="w-full text-left">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-4 py-2 font-medium">Subject/Keyword/Name</th>
                        <th className="px-4 py-2 font-medium">Detail</th>
                        <th className="px-4 py-2 font-medium">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index} className="border-b">
                            <td className="px-4 py-2">{item.Subject || item.Keyword || item.Name || item.Question}</td>
                            <td className="px-4 py-2">{item.Hint || item.Introduction || item.Answers}</td>
                            <td className="px-4 py-2 flex gap-2">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                                    onClick={() => handleShowModal(type, item)}
                                >
                                    Edit
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-semibold mb-4">{product?.Name}</h1>
            {product?.Description && (
                <div className="mb-8">{formatDescription(product.Description)}</div>
            )}

            {product.productHints && renderTable(product.productHints, "Product Hints", 'productHints')}
            {product.productQuestions && renderTable(product.productQuestions, "Product Questions", 'productQuestions')}
            {product.productKeywords && renderTable(product.productKeywords, "Product Keywords", 'productKeywords')}
            {product.productIntros && renderTable(product.productIntros, "Product Introductions", 'productIntros')}
            {renderModalForm()}
        </div>
    );
}

export default ViewProduct;